export default [
  {
    header: 'Nova-Tender',
  },
  {
    title: 'Administration',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Profiles',
        route: 'tender-profiles-list',
        icon: 'MapIcon',
      },

      {
        title: 'Clients',
        route: 'clients-list',
        resource: 'clients',
        action: 'view',
        icon: 'UserIcon',
      },
      {
        title: 'Users',
        icon: 'UsersIcon',
        route: 'users-list',
        resource: 'users',
        action: 'view',
      },
      {
        title: 'Email history',
        route: 'daily-reports-history',
        icon: 'SendIcon',
      },
    ],
  },
  {
    title: 'My tenders',
    route: 'my-tenders-list',
    resource: 'my-tenders',
    action: 'view',
    icon: 'ListIcon',
  },
  {
    title: 'Tenders search',
    route: 'tenders-list',
    resource: 'tenders-search',
    action: 'view',
    icon: 'SearchIcon',
  },

]
